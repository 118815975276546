import "./authRole.scss";
import { Tabs, Tag, Popover, Toast } from "antd-mobile";
import { useLocation, useNavigate } from "react-router-dom";
import { CloseShieldOutline, CheckShieldOutline } from "antd-mobile-icons";
import { useEffect, useState } from "react";
import getCloudImg from "../../unit/getCloudImg";
import Request from "../../unit/request";
import { useLoginStore } from "../../store";
import qs from "qs";
import NoData from "../../components/noData/noData";
const PublishExam = () => {
  sessionStorage.removeItem("newsEdit");
  const navigate = useNavigate();
  const location = useLocation();

  const [activeList, setActiveList] = useState([]);
  const { GetUserInfo } = useLoginStore((state) => state);
  const getInstitutionList = () => {
    Request({
      url: "/institution/list",
      method: "post",
      data: {
        ins_id: "all",
      },
    }).then(
      (res) => {
        getCloudImg({
          key: "venPhoto1",
          list: res.data,
        }).then((list) => {
          setActiveList(list);
        });
      },
      (err) => {
        setActiveList([]);
      }
    );
  };
  const getExamineList = () => {
    Request({
      url: "/examine/list",
      method: "post",
    }).then(
      (res) => {
        getCloudImg({
          key: "photo",
          list: res.data,
        }).then((list) => {
          setActiveList(list);
        });
      },
      (err) => {
        setActiveList([]);
      }
    );
  };
  const examineList = (v) => {
    return (
      <div className="authRoleItem" onClick={() => toExamineDetail(v)}>
        <div className="examine">
          <div className="examineImg">
            <img src={v.photo} alt="" />
          </div>
          <div className="examineData">
            <div className="examineInfo">
              <div className="examineTitle">{v.name}</div>
              <div className="examineInfoItem">
                <span>
                  性别：{v.gender === 1 ? "男" : v.gender === 2 ? "女" : "未知"}
                </span>
                <span>学校：{v.school}</span>
                <span>电话：{v.phoneNumber}</span>
              </div>
            </div>

            {rootBtn(v, v.user_id, "examine")}
          </div>
        </div>
      </div>
    );
  };
  const institutionList = (v) => {
    return (
      <div className="authRoleItem" onClick={() => toInsititution(v)}>
        <div className="institution">
          <div className="institutionTitle">{v.name}</div>
          <div className="institutionImg">
            <img src={v.venPhoto1} alt="" />
          </div>
          <div className="institutionData">
            <div className="institutionInfo">
              <span>
                所在城市：{v.address ? v.address.replace("|@|", "-") : "-"}
              </span>
              <span>详细位置：{v.detialAdress || "-"}</span>
              <span>机构电话：{v.phoneNumber}</span>
            </div>
            {rootBtn(v, v.institution_user_id, "insititution")}
          </div>
        </div>
      </div>
    );
  };
  const tabsActiveInterFace = {
    examine: "1",
    institution: "2",
  };
  let _tabsActive;
  const { type } = qs.parse(location.search, { ignoreQueryPrefix: true });
  if (type) {
    _tabsActive = tabsActiveInterFace[type];
  }
  const [tabsActive] = useState(_tabsActive || "1");
  const examine = (type, id, verify) => {
    const url = `/admin/audit/${type}`;
    let data = {
      verify,
    };
    data.user_id = id;
    Request({
      url,
      method: "post",
      data,
    }).then((res) => {
      getList();
    });
  };
  const examPopAction = [
    { key: "1", icon: <CheckShieldOutline />, text: "通过" },
    { key: "2", icon: <CloseShieldOutline />, text: "不通过" },
  ];
  const rootBtn = (v, id, type) => {
    const { verify } = v;
    let el = null;
    switch (verify) {
      case 0:
        el = (
          <Popover.Menu
            actions={examPopAction}
            placement="bottom-start"
            onAction={(node) => {
              examine(type, id, node.key);
            }}
            stopPropagation={["click"]}
            trigger="click"
          >
            <div className="examineBtn">审核</div>
          </Popover.Menu>
        );
        break;
      case 1:
        el = (
          <Tag color="#87d068" fill="outline">
            审核通过
          </Tag>
        );
        break;
      case 2:
        el = (
          <Tag color="#ff6430" fill="outline">
            审核未通过
          </Tag>
        );
        break;
      default:
        break;
    }
    return el;
  };
  const getList = () => {
    switch (tabsActive) {
      case "1":
        getExamineList();
        break;
      case "2":
        getInstitutionList();
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    GetUserInfo().then(() => {
      getList();
    });
  }, []);
  const toInsititution = (v) => {
    navigate(`/institutions?ins_id=${v.institution_id}&type=admin`);
  };
  const toExamineDetail = (v) => {
    navigate(`/examinerReport?examine_id=${v.detail_id}&type=admin`);
  };
  const createActiveList = (v) => {
    switch (tabsActive) {
      case "1":
        return examineList(v);
      case "2":
        return institutionList(v);
      default:
        break;
    }
  };
  return (
    <div className="authRole publishExam">
      <div className="examBox">
        <NoData
          list={activeList}
          text={tabsActive === "1" ? "暂无考官认证" : "暂无机构认证"}
        />
        {activeList.map((v) => createActiveList(v))}
      </div>
    </div>
  );
};

export default PublishExam;
