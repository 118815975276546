import tcb from "@cloudbase/js-sdk";
const app = tcb.init({
	env: "bjsports-3gjwsnfg45ed42fd"
});
const getCloudImg = (data) => {
	return new Promise((r, j) => {
		let key = data.key
		const isFull = data.isFull || false
		let alias = data.alias
		let urls = data.list.filter(v => v[key]).map(v => v[key])
		app.getTempFileURL({
			fileList: urls
		}).then((res) => {
			let { fileList } = res
			fileList.forEach((v) => {
				v.serverId = v.fileID
				data.list.forEach(l => {
					if (l[key] === v.fileID) {
						if (isFull) {
							Object.assign(l, v)
						}
						l[alias || key] = v.tempFileURL
					}
				})
			})
			r(data.list)
		}, err => {
			r(data.list)
		});
	})
}

export default getCloudImg