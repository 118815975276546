import { Dropdown, Radio, Space, Toast, Button, Dialog } from "antd-mobile";
import { DownOutline } from "antd-mobile-icons";
import { Fragment, useRef, useState } from "react";
import getApplyDetail from "../util/getApplyDetail";
import { useEffect } from "react";
import Request from "../../../unit/request";
import NoData from "../../../components/noData/noData";
import upload from "../../../unit/uploadImg";
const ApplyExam = (props) => {
  const [isScore, setIsScore] = useState(0);
  const [applyStudentList, setApplyStudentList] = useState([]);
  const getData = () => {
    const params = {
      type: "student",
      exam_id: props.exam_id,
      filter: (v) => {
        if (isScore === 0) {
          return v.verify === 1 && v.scoreList.length === 0;
        } else {
          return v.verify === 1 && v.scoreList.length > 0;
        }
      },
    };
    getApplyDetail(params).then((res) => {
      setApplyStudentList(res);
    });
  };
  const toDetailPage = (item) => {
    sessionStorage.studentEdit = JSON.stringify(item);
    navigator(`/report?type=admin`);
  };

  const getScoreTmp = () => {
    Request({
      url: "/admin/exam/downloadScoreTemplate",
      method: "post",
      data: {
        exam_id: props.exam_id,
      },
    }).then((res) => {
      window.location.href = res.data[0].url;
    });
  };
  const setScoreTmp = (e) => {
    upload({
      files: e.target.files,
      type: "scoreTmp",
      path: `${props.exam_id}/scoreTmp`,
    }).then((file) => {
      Request({
        url: "/admin/exam/score",
        method: "post",
        data: {
          exam_id: props.exam_id,
          fildId: file[0].fileID,
        },
      }).then((res) => {
        Toast.show({
          icon: "success",
          content: `成功导入${res.data.updateNum}条成绩`,
        });
        getData();
      });
    });
  };
  const uploadTmpRef = useRef(null);
  useEffect(() => {
    getScoreTmp();
    getData();
    let _class = document.body.className;
    document.body.setAttribute("class", `${_class} examDetailPopBody`);
    return () => {
      document.body.removeAttribute(
        "class",
        _class.replace("examDetailPopBody", "")
      );
    };
  }, []);
  return (
    <Fragment>
      <div className="examiner">
        <div className="examinerBlockTitle">
          考生评分
          <Dropdown arrow={<DownOutline />} onChange={(v) => !v && getData()}>
            <Dropdown.Item
              key="sorter1"
              title={isScore === 0 ? "待评分" : "已评分"}
            >
              <div className="radioBox">
                <Radio.Group defaultValue={0} onChange={setIsScore}>
                  <Space direction="vertical">
                    <Radio value={0}>待评分</Radio>
                    <Radio value={1}>已评分</Radio>
                  </Space>
                </Radio.Group>
              </div>
            </Dropdown.Item>
          </Dropdown>
        </div>
        <div className="applyList">
          <NoData list={applyStudentList} />
          {applyStudentList.map((v, i) => (
            <div className="applyItemBox">
              <div className="applyItem">
                <div className="applyLeft" onClick={() => toDetailPage(v)}>
                  <img src={v.img} className="applyImg" alt="" />
                </div>
                <div className="applyRight">
                  <div className="applyInfo" onClick={() => toDetailPage(v)}>
                    <div className="applyInfoItem applyInfoName">{v.name}</div>
                    <div className="applyInfoItem">
                      性别：
                      {v.gender === 1 ? "男" : v.gender === 2 ? "女" : "未知"}
                    </div>
                    <div className="applyInfoItem">
                      手机号：
                      {v.phoneNumber}
                    </div>
                    <div className="applyInfoItem">
                      <svg className="icon" aria-hidden="true">
                        <use xlinkHref="#icon-weizhi1"></use>
                      </svg>
                      {v.address && v.address.split("|@|")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="scoreItemBox">
                {v.scoreList.length === 0 && (
                  <div className="scoreItem">
                    <p>考试成绩</p>
                    待评分
                  </div>
                )}
                {v.scoreList.map((s) => (
                  <div className="scoreItem">
                    <p>{s.name}</p>
                    {s.score}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <input
        type="file"
        ref={uploadTmpRef}
        className="uploadTmp"
        id="uploadTmp"
        onChange={setScoreTmp}
      />
      <Space direction="vertical" block>
        <Button
          block
          shape="rounded"
          color="primary"
          onClick={() => {
            uploadTmpRef.current?.click();
          }}
        >
          导入分数
        </Button>
        <Button
          className="downloadTmpBtn"
          block
          shape="rounded"
          color="primary"
          onClick={() => {
            Dialog.alert({
              image: require("../../../assets/guide.png"),
              title: "下载成绩模版示例",
              content:
                "您可以选择在电脑上打开并编辑，也可在手机浏览器中打开下载并编辑",
              onConfirm: () => {
                getScoreTmp();
              },
            });
          }}
        >
          下载模版
        </Button>
      </Space>
    </Fragment>
  );
};

export default ApplyExam;
