import { Space, Tag, Popover, Toast } from "antd-mobile";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import getCloudImg from "../../unit/getCloudImg";
import Request from "../../unit/request";
import "./adminApplyExam.scss";
import NoData from "../../components/noData/noData";
const AdminApplyExam = () => {
  const [examList, setExamList] = useState([]);
  const navigate = useNavigate();
  const getList = () => {
    Request({
      url: "/admin/exam/enrollList",
      method: "post",
    }).then(
      (res) => {
        getCloudImg({
          key: "exam_field",
          list: res.data,
        }).then((list) => {
          setExamList(list);
        });
      },
      (err) => {
        setExamList([]);
      }
    );
  };
  const toDetail = (v) => {
    navigate(`/examDetail?exam_id=${v.exam_id}&type=apply`);
  };
  useEffect(() => {
    getList();
  }, []);
  return (
    <div className="adminApplyExam">
      <div className="examBox">
        <NoData list={examList} />
        {examList.map((v) => (
          <div className="examItem">
            <img src={v.exam_field} alt="" />
            <div className="examInner">
              <div className="examInfo">
                <p className="examTitle">{v.exam_name}</p>
                <Space>
                  <Tag color="primary" fill="outline">
                    {v.exam_category}
                    {v.exam_rank && `${v.exam_rank}级`}
                  </Tag>
                  <Tag color="#87d068" fill="outline">
                    报考学生{v.stu_Num || 0}人
                  </Tag>
                  <Tag color="#87d068" fill="outline">
                    考官申请{v.eexmine_Num || 0}人
                  </Tag>
                </Space>

                <p className="examAddress">
                  <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#icon-weizhi1"></use>
                  </svg>
                  {v.exam_address && v.exam_address.split("|@|").join("-")}
                </p>
                <p className="examDate">
                  <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#icon-shijian"></use>
                  </svg>
                  {v.exam_date.split(" ")[0]}
                </p>
              </div>
              <div className="examBtnBox">
                <div className="examBtn" onClick={() => toDetail(v)}>
                  <p>审核</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminApplyExam;
