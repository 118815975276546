import { useState } from "react";
import { Toast, Dialog, Button, Space, Popup, Selector } from "antd-mobile";
import Request from "../../../unit/request";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import examPay from "../../../unit/examPay";
import { useLoginStore } from "../../../store";
const Bottom = (props = {}) => {
  const { loginInfo } = useLoginStore((state) => state);
  const navigator = useNavigate();
  const [bottomVisible, setBottomVisible] = useState(false);
  const [studentId, setStudentId] = useState("");
  const examine = (verify) => {
    const url = `/admin/audit/exam`;
    let data = {
      verify,
      exam_id: props.exam_id,
    };
    Request({
      url,
      method: "post",
      data,
    }).then((res) => {
      Toast.show({
        icon: "success",
        content: "审核已完成",
      });
      setTimeout(() => {
        navigator(-1);
      }, 1500);
    });
  };
  const studentSubmit = async () => {
    if (!bottomVisible) {
      getStudent();
      setBottomVisible(true);
      return;
    }
    if (!studentId) {
      Toast.show({
        content: "请选择报考学生",
      });
      return;
    }
    const topRank = await Request({
      url: "/exam/examCanEnroll",
      method: "post",
      data: {
        student_id: studentId,
        exam_category: props.exam_category,
      },
    });
    if (topRank.data.upper) {
      Toast.show({
        content: `该考生只能报${props.exam_category}${topRank.data.exam_rank}级及以下级别的考试`,
      });
      return;
    }
    Request({
      url: "/exam/enroll",
      method: "post",
      data: {
        exam_id: props.exam_id,
        student_id: studentId,
      },
    }).then(async (res) => {
      Toast.show({
        icon: "success",
        content: "报名成功，即将发起支付",
      });
      await examPay(props.exam_id, studentId);
      setTimeout(() => {
        navigator("/myExamList");
      }, 1500);
    });
  };

  const examineSubmit = () => {
    if (!!loginInfo.userInfo.user_level_id === false) {
      Toast.show({
        content: "考官认证完成后开放",
      });
      return;
    }
    Dialog.confirm({
      content: "确认申请该场次监考？",
      onConfirm: async () => {
        await Request({
          url: "/examine/enroll",
          method: "post",
          data: {
            exam_id: props.exam_id,
          },
        });
        Toast.show({
          icon: "success",
          content: "申请成功",
        });
        setTimeout(() => {
          navigator(-1);
        }, 1500);
      },
    });
  };
  const [student, setStudent] = useState([]);
  const getStudent = () => {
    Request({
      url: "/user/myInnerUser",
      method: "get",
    }).then((res) => {
      let arr = res.data.myInnerUser.filter((v) => v.userLevel === 1);
      arr.forEach((v) => {
        v.value = v.detail_id;
        v.label = v.name;
      });
      setStudent(arr);
    });
  };
  const toAdd = () => {
    navigator("/report");
  };
  const adminBtn = () => {
    let el = null;
    switch (props.verify) {
      case 0:
        el = (
          <div className="adminBtn">
            <Space direction="vertical" block>
              <Button
                block
                shape="rounded"
                color="primary"
                onClick={() => examine(1)}
              >
                通过
              </Button>
              <Button
                block
                shape="rounded"
                color="danger"
                onClick={() => examine(2)}
              >
                不通过
              </Button>
            </Space>
          </div>
        );
        break;
      case 1:
        el = (
          <div className="adminBtnOther">
            <Button block shape="rounded" color="primary">
              审核已通过
            </Button>
          </div>
        );
        break;
      case 2:
        el = (
          <div className="adminBtnOther">
            <Button block shape="rounded" color="danger">
              审核未通过
            </Button>
          </div>
        );
        break;
      default:
        break;
    }
    return el;
  };
  const bottomEl = () => (
    <div className="bottom">
      <div className="bottomBarBlock">
        <div className="bottomBlockInner"></div>
        <div className="bottomBarSafe"></div>
      </div>
      {!props.isExaminer ? (
        <div className="bottomInner">
          <div className="bottomBlockInner">
            <div className="price">
              <p className="priceName">考试费用</p>
              <p className="num">¥{props.fee}</p>
            </div>
            {props.examSurplus > 0 ? (
              <div className="btnBox">
                <div className="btn" onClick={studentSubmit}>
                  <p>立即报名</p>
                </div>
                <span>剩余名额{props.examSurplus}</span>
              </div>
            ) : (
              <div className="btnBox">
                <div className="btn" style={{ background: "#ccc" }}>
                  <p>停止报名</p>
                </div>
                <span>剩余名额{props.examSurplus}</span>
              </div>
            )}
          </div>
          <div className="detailBottomBarSafe"></div>
        </div>
      ) : (
        <div className="bottomExaminerBtn">
          {props.examSurplus > 0 ? (
            <div className="btn" onClick={examineSubmit}>
              <p>申请监考</p>
            </div>
          ) : (
            <div className="btn" style={{ background: "#ccc" }}>
              <p>申请结束</p>
            </div>
          )}
        </div>
      )}
      <Popup
        getContainer={null}
        visible={bottomVisible}
        onMaskClick={() => {
          setBottomVisible(false);
        }}
        bodyStyle={{ paddingBottom: "140px" }}
      >
        <div className="reportDetail">
          <p className="reportDetailTitle">请选择考生</p>
          {student.length <= 0 && (
            <div className="noStudent">暂无考生，请先添加考生</div>
          )}
          <Selector
            options={student}
            onChange={(arr) => setStudentId(arr[0])}
          />
          <div className="examReportNoThing" onClick={toAdd}>
            <svg className="icon" aria-hidden="true">
              <use xlinkHref="#icon-jiahao1"></use>
            </svg>
            添加考生
          </div>
        </div>
      </Popup>
    </div>
  );
  const returnBottom = () => {
    if (props.type === "admin") {
      return adminBtn();
    }
    if (!props.type) {
      return bottomEl();
    }
    return <></>;
  };
  return returnBottom();
};

export default Bottom;
