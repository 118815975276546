import { Swiper, Toast } from "antd-mobile";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/header/header";
import { useLoginStore } from "../../store";
import "./index.scss";
import Request from "../../unit/request";
import { useState } from "react";
import getCloudImg from "../../unit/getCloudImg";
import qs from "qs";
const quickLink = [
  {
    key: 0,
    text: "报名流程",
    icon: "icon-yunying",
  },
  {
    key: 1,
    text: "报名通道",
    icon: "icon-baoming",
    page: "/main/exam",
  },
  {
    key: 2,
    text: "成绩查询",
    icon: "icon-pandian",
    page: "/myExamList?type=search",
  },
  {
    key: 3,
    text: "证书查询",
    icon: "icon-mubiao",
    noThing: true,
  },
];
const quickLink2 = [
  {
    key: 4,
    text: "考官认证",
    icon: "icon-renyuan",
    isLogin: true,
    webRole: "examiner",
    page: "/examinerReport",
  },
  {
    key: 5,
    text: "机构认证",
    isLogin: true,
    webRole: "institutions",
    icon: "icon-zhuancang",
    page: "/institutions",
  },
  {},
  {},
];
const Index = () => {
  const [indexItem, setIndexItem] = useState();
  const [examList, setExamList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const getData = () => {
    Request({
      url: "/index/index",
      method: "get",
    }).then((res) => {
      getCloudImg({
        list: res.data.banner,
        key: "img",
      }).then((banners) => {
        let br = banners.map((item, index) => (
          <Swiper.Item key={index}>
            <div className="indexItem" onClick={() => toDetail(item)}>
              <img className="swiperImg" src={item.img} alt="" />
              <div className="swiperShadow">
                <div className="swiperTitle">
                  北京市2022年阳光体育中小学生羽毛球赛正式开始
                </div>
                <div className="swiperTime">2022-10-01</div>
              </div>
            </div>
          </Swiper.Item>
        ));
        setIndexItem(br);
        //setBannerList(banners);
      });
      getCloudImg({
        list: res.data.exam.slice(0, 2),
        key: "exam_field",
      }).then((exam) => {
        setExamList(exam);
      });
      setNewsList(res.data.news);
    });
  };
  const navigate = useNavigate();
  const { search } = useLocation();

  const { ToLogin, loginInfo, GetUserInfo } = useLoginStore((state) => state);
  const toNewsList = () => {
    navigate("/newsList");
  };
  const toDetail = (v) => {
    navigate(`/newsDetail?news_id=${v.id}`);
  };
  const toExamList = () => {
    navigate(`/main/exam`);
  };
  const toExamDetail = (v) => {
    if (loginInfo.isLogin) {
      navigate(`/examDetail?exam_id=${v.exam_id}`);
    } else {
      ToLogin(`/#/examDetail?exam_id=${v.exam_id}`);
    }
  };
  useEffect(() => {
    GetUserInfo().then((user) => {
      let role =
        qs.parse(search, { ignoreQueryPrefix: true })?.role ||
        sessionStorage.webRole;
      if (role) {
        if (!!user.userInfo.user_level_id === false) {
          sessionStorage.webRole = role;
        } else {
          let roleLabel = "";
          switch (user.userInfo.user_level_id) {
            case 1:
              roleLabel = "学生";
              break;
            case 2:
              roleLabel = "考官";
              break;
            case 3:
              roleLabel = "评测中心管理员";
              break;
            case 4:
              roleLabel = "承办机构管理员";
              break;
            case 5:
              roleLabel = "组委会";
              break;
            default:
              break;
          }
          Toast.show({
            icon: "success",
            content: `您的账号已注册角色为${roleLabel}，即将跳转至${roleLabel}功能页`,
          });
          sessionStorage.removeItem("webRole");
          setTimeout(() => {
            navigate("/main/index");
            window.location.reload();
          }, 1500);
        }
      }
    });
    getData();
  }, []);
  return (
    <div className="index">
      <Header />
      <div className="indexSwiperBox">
        <Swiper
          style={{
            "--track-padding": " 0 0 22px",
          }}
          defaultIndex={0}
        >
          {indexItem}
        </Swiper>
      </div>
      <div className="newsExam content" style={{ marginTop: "30px" }}>
        <div className="title">
          最新评测
          <span className="titleMore" onClick={toExamList}>
            查看更多
          </span>
        </div>
        <div className="newsExamInner">
          {examList.length <= 0 && <div className="noDataExam">暂无评测</div>}
          {examList.map((v) => (
            <div className="newsExamInnerItem" onClick={() => toExamDetail(v)}>
              <img src={v.exam_field} alt="" />
              <p className="stationName">{v.exam_name}</p>
              <p className="stationAddress">{v.exam_detailAddress}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="newsInfo content">
        <div className="title">
          最新动态
          {/*<span className="titleMore" onClick={toNewsList}>
            查看更多
          </span>*/}
        </div>
        <div className="newsInfoInner">
          {newsList.length <= 0 && <div className="noDataNew">暂无动态</div>}
          {newsList.map((v, i) => (
            <div
              className="newsInfoInnerItem"
              key={i}
              onClick={() => toDetail(v)}
              rel="noreferrer"
            >
              <p className="newsInfoTitle">{v.title}</p>
              <div className="newsSubInfo">
                <p className="newsInfoSource">{v.href}</p>
                <p className="newsInfoDate">{v.date}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Index;
