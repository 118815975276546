import { Tabs, Space, Tag, Picker } from "antd-mobile";
import { DownOutline } from "antd-mobile-icons";
import { useState, useEffect } from "react";
import { useLoginStore } from "../../store";
import getCloudImg from "../../unit/getCloudImg";
import Request from "../../unit/request";
import "./enrollManage.scss";
import NoData from "../../components/noData/noData";

const EnrollManage = () => {
  const { loginInfo } = useLoginStore((state) => state);
  const [list, setList] = useState([]);
  const [indValue, setIndValue] = useState([]);

  const getList = (id) => {
    Request({
      url: "/admin/exam/enrollList",
      method: "post",
      data: {
        ins_id: id,
      },
    }).then((res) => {
      getCloudImg({
        key: "exam_field",
        list: res.data,
      }).then((list) => {
        setList(list);
      });
    });
  };
  useEffect(() => {
    getList();
  }, []);
  return (
    <div className="enrollManageBox">
      <div className="examBox">
        <NoData list={list} />
        {list.map((v) => (
          <div className="examItem">
            <img src={v.exam_field} alt="" />
            <div className="examInner">
              <div className="examInfo">
                <p className="examTitle">{v.exam_name}</p>
                <Space>
                  <Tag color="primary" fill="outline">
                    {v.exam_category}
                    {v.exam_rank && `${v.exam_rank}级`}
                  </Tag>
                </Space>
                <p className="examAddress">
                  <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#icon-weizhi1"></use>
                  </svg>
                  {v.exam_address && v.exam_address.split("|@|").join("-")}
                </p>
                <p className="examDate">
                  <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#icon-shijian"></use>
                  </svg>
                  {v.exam_date.split(" ")[0]}
                </p>
              </div>
              <div className="examBtnBox">
                {/*<div className="examBtn" onClick={() => toDetail(v)}>
                    <p>审核</p>
                  </div>*/}
              </div>
            </div>
            <div className="examData">
              <p className="examDataTitle">学生数据</p>
              <div className="examDataItem">
                <div className="examDataInfo">报名：{v.stu_Num || 0} 人</div>
                {/*<div className="examDataInfo">
                  审核通过：{v.stu_verify_num || 0} 人
                </div>*/}
                <div className="examDataInfo">
                  考试通过：{v.stu_pass_num || 0} 人
                </div>
              </div>
              <p className="examDataTitle">考官数据</p>
              <div className="examDataItem">
                <div className="examDataInfo">
                  申请：{v.examine_Num || 0} 人
                </div>
                {/*<div className="examDataInfo">
                  审核通过：{v.examine_verify_Num || 0} 人
                </div>
                <div className="examDataInfoNone">
                  审核通过：{v.examine_verify_Num || 0} 人
                </div>*/}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EnrollManage;
