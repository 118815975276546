import "./bottom.css";
import { useNavigate, useLocation } from "react-router-dom";
const Bottom = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const toPage = (e) => {
    navigate(e);
  };
  return (
    <div className="bottomBar">
      <div className="bottomBarBlock">
        <div className="bottomBarBlockInner"></div>
        <div className="bottomBarSafe"></div>
      </div>
      <div className="bottomBarInner">
        <div className="bottomBox">
          <div className={`bottomItem ${location.pathname === '/' || location.pathname === '/main/index'?'active':''}`} onClick={() => toPage("/")}>
            <svg className="icon" aria-hidden="true">
              <use xlinkHref="#icon-zhuye"></use>
            </svg>
            <p>主页</p>
          </div>
          <div className={`bottomItem ${location.pathname === '/main/exam'?'active':''}`} onClick={() => toPage("/main/exam")}>
            <svg className="icon" aria-hidden="true">
              <use xlinkHref="#icon-zhijianjieguo"></use>
            </svg>
            <p>考试</p>
          </div>
          <div className={`bottomItem ${location.pathname === '/main/my'?'active':''}`} onClick={() => toPage("/main/my")}>
            <svg className="icon" aria-hidden="true">
              <use xlinkHref="#icon-yuangongguanli"></use>
            </svg>
            <p>我的</p>
          </div>
        </div>
        <div className="bottomBarSafe"></div>
      </div>
    </div>
  );
};

export default Bottom;
