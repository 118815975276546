import {
  Form,
  Input,
  Radio,
  Space,
  Button,
  Checkbox,
  Picker,
  DatePicker,
  Cascader,
  Toast,
  Dialog,
} from "antd-mobile";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import verify from "../../unit/verify";
import { useCityData } from "../../store";
import "./report.css";
import Request from "../../unit/request";
import UploaderImg from "../../components/uploadImg/uploadImg";
import SendCode from "../../components/sendCode/sendCode";
import SearchSchool from "./components/searchSchool/searchSchool";
import dayjs from "dayjs";
import qs from "qs";
import getCloudImg from "../../unit/getCloudImg";
import { ExclamationCircleFill } from "antd-mobile-icons";
const Report = () => {
  const { cityData } = useCityData((state) => state);
  const [isProtocol, setIsProtocol] = useState(false);
  const [init, setInit] = useState({});
  const _setInit = () => {
    let _init;
    try {
      _init = JSON.parse(sessionStorage.studentEdit);
      if (_init.address) {
        _init.address = _init.address.split("|@|");
      }

      if (_init.birthday) {
        _init.birthday = new Date(_init.birthday);
      }
      getCloudImg({
        key: "img",
        isFull: true,
        list: [{ img: _init.photo }],
      }).then((list) => {
        _init.photo = [list[0]];
        setInit(_init);
        if (form.current) {
          form.current.setFieldsValue(_init);
        }
      });
    } catch (error) {}
  };
  const navigate = useNavigate();
  const submit = (e) => {
    const params = e;
    if (params.address && params.address.length) {
      params.address = params.address.join("|@|");
    }
    if (params.photo) {
      params.photo = params.photo[0]?.serverId;
    }
    if (params.birthday) {
      params.birthday = dayjs(params.birthday).format("YYYY-MM-DD");
    }
    params.isProtocol = isProtocol;
    let rule = [
      { key: "name", msg: "请输入姓名" },
      { key: "idcard", msg: "请输入证件号码" },

      { key: "address", msg: "请选择所在城市" },
      { key: "birthday", msg: "请选择生日" },

      { key: "school", msg: "请输入所在学校" },

      { key: "photo", msg: "请上传正身照" },
      { key: "isProtocol", value: true, msg: "请同意报名协议" },
    ];

    let sendCodeInfo = sendCode.current;
    if (sendCodeInfo) {
      params.smsCode = sendCodeInfo.smsCode;
      params.phoneNumber = sendCodeInfo.phoneNum;
      rule.push({ key: "phoneNumber", msg: "请输入手机号" });
      rule.push({ key: "smsCode", msg: "请输入验证码" });
    }

    const _verify = verify(params, rule);
    if (_verify) {
      Toast.show({
        content: _verify.msg,
      });
      return;
    }
    const data = {
      userLevel: 1,
      form: params,
    };
    if (type === "edit") {
      data.student_id = init.student_id;
    }
    Request({
      url: type === "edit" ? "/user/modify" : "/user/auth",
      method: "post",
      data,
    }).then((res) => {
      Toast.show({
        icon: "success",
        content: "提交成功",
      });
      setTimeout(() => {
        navigate(-1);
      }, 1500);
    });
  };

  const toProtocol = () => {
    navigate(`/protocol`);
  };
  const form = useRef(null);
  const sendCode = useRef(null);
  const { search } = useLocation();
  const { type } = qs.parse(search, { ignoreQueryPrefix: true });
  useEffect(() => {
    _setInit();
  }, []);
  return (
    <div className="report">
      <Form
        ref={form}
        layout="horizontal"
        mode="card"
        onFinish={submit}
        footer={
          <div>
            {type === "admin" ? (
              <></>
            ) : (
              <>
                <Button block type="submit" color="primary" size="large">
                  {type === "edit" ? "确认修改" : "提交"}
                </Button>
                {type === "edit" ? (
                  <Button
                    block
                    className="editDeleteBtn"
                    color="danger"
                    size="large"
                    onClick={() => {
                      Dialog.confirm({
                        header: (
                          <ExclamationCircleFill
                            style={{
                              fontSize: 64,
                              color: "var(--adm-color-warning)",
                            }}
                          />
                        ),
                        title: "确定删除该考生吗？",
                        content: (
                          <>
                            <div className="deleteStuInfo">
                              删除考生后，相关信息也将一并删除
                            </div>
                          </>
                        ),
                        onConfirm: async () => {
                          await Request({
                            url: "/user/deletemyStudent",
                            method: "post",
                            data: {
                              student_id: init.student_id,
                            },
                          });
                          Toast.show({
                            icon: "success",
                            content: "已删除",
                          });
                          setTimeout(() => {
                            navigate(-1);
                          }, 1500);
                        },
                      });
                    }}
                  >
                    删除考生
                  </Button>
                ) : (
                  <Checkbox
                    className="checkBoxSubmit"
                    onChange={(val) => {
                      setIsProtocol(!!val);
                    }}
                  >
                    <div className="reportProtocol">
                      已阅读同意
                      <div className="examinerLink" onClick={toProtocol}>
                        《考生网上报名协议》
                      </div>
                    </div>
                  </Checkbox>
                )}
              </>
            )}
          </div>
        }
      >
        <Form.Header>考生信息</Form.Header>
        <Form.Item label="姓名" name="name" required={false}>
          <Input placeholder="请输入" readOnly={type === "admin"} />
        </Form.Item>
        <Form.Item label="性别" initialValue={1} name="gender">
          <Radio.Group disabled={type === "admin"}>
            <Space>
              <Radio value={1}>男</Radio>
              <Radio value={2}>女</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="证件类型" required={false} arrow>
          <Input placeholder="请选择证件类型" value="中国居民身份证" disabled />
        </Form.Item>
        <Form.Item label="证件号码" name="idcard" required={false}>
          <Input placeholder="请输入证件号码" readOnly={type === "admin"} />
        </Form.Item>
        <Form.Item
          label="生日"
          onClick={(e, datePickerRef) => {
            if (type === "admin") {
              return;
            }
            datePickerRef.current?.open();
          }}
          name="birthday"
          trigger="onConfirm"
          required={false}
        >
          <DatePicker
            title="选择生日"
            min={new Date(new Date().getFullYear() - 50)}
            max={new Date()}
          >
            {(date) => {
              if (date) {
                return dayjs(date).format("YYYY-MM-DD");
              } else {
                return "请选择生日";
              }
            }}
          </DatePicker>
        </Form.Item>
        <Form.Item
          label="正身照"
          name="photo"
          help="两寸蓝底正身照"
          required={false}
        >
          <UploaderImg path="student" isView={type === "admin"} />
        </Form.Item>
        <Form.Item
          trigger="onConfirm"
          label="所在学校"
          name="school"
          required={false}
          onClick={(e, SearchSchoolRef) => {
            SearchSchoolRef.current?.open();
          }}
        >
          <SearchSchool />
        </Form.Item>
        <Form.Item
          label="所在城市"
          name="address"
          required={false}
          trigger="onConfirm"
          onClick={(e, cascaderRef) => {
            if (type === "admin") {
              return;
            }
            cascaderRef.current?.open();
          }}
        >
          <Cascader options={cityData}>
            {(e) => {
              if (e && e.length) {
                return e.map((v) => v.label).join("-");
              } else {
                return "请选择所在城市";
              }
            }}
          </Cascader>
        </Form.Item>
        <Form.Header></Form.Header>
        {!type && <SendCode ref={sendCode} />}
      </Form>
    </div>
  );
};
export default Report;
