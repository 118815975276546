import "./examinerStore.css";
import { Tabs, Tag } from "antd-mobile";
import Request from "../../unit/request";
import { useEffect, useState } from "react";
import { useLoginStore } from "../../store";
import { useNavigate } from "react-router-dom";
import getCloudImg from "../../unit/getCloudImg";
import NoData from "../../components/noData/noData";
const ExaminerStore = () => {
  const navigator = useNavigate();
  const { GetUserInfo, loginInfo } = useLoginStore((state) => state);
  const [activeList, setActiveList] = useState([]);
  const getExaminerList = () => {
    Request({
      url: "/examine/list",
      method: "post",
      data: {
        ins_id: loginInfo.userInfo.institution_id,
      },
    }).then(
      (res) => {
        getCloudImg({
          key: "photo",
          list: res.data,
        }).then((list) => {
          setActiveList(list);
        });
      },
      (err) => {
        setActiveList([]);
      }
    );
  };
  const toExamineDetail = (v) => {
    navigator(`/examinerReport?examine_id=${v.detail_id}&type=self`);
  };
  const rootBtn = (v) => {
    const { verify } = v;
    let el = null;
    switch (verify) {
      case 0:
        el = (
          <Tag color="primary" fill="outline">
            审核中
          </Tag>
        );
        break;
      case 1:
        el = (
          <Tag color="#87d068" fill="outline">
            审核通过
          </Tag>
        );
        break;
      case 2:
        el = (
          <Tag color="#ff6430" fill="outline">
            审核未通过
          </Tag>
        );
        break;
      default:
        break;
    }
    return el;
  };
  const examineList = (v) => {
    return (
      <div className="authRoleItem" onClick={() => toExamineDetail(v)}>
        <div className="examine">
          <div className="examineImg">
            <img src={v.photo} alt="" />
          </div>
          <div className="examineData">
            <div className="examineInfo">
              <div className="examineTitle">{v.name}</div>
              <div className="examineInfoItem">
                <span>
                  性别：{v.gender === 1 ? "男" : v.gender === 2 ? "女" : "未知"}
                </span>
                <span>学校：{v.school}</span>
                <span>电话：{v.phoneNumber}</span>
              </div>
            </div>

            {rootBtn(v)}
          </div>
        </div>
      </div>
    );
  };
  const activeChange = (e) => {
    if (e === "1") {
      getExaminerList();
    } else {
      setActiveList([]);
    }
  };
  useEffect(() => {
    GetUserInfo().then(() => {
      getExaminerList();
    });
  }, []);
  return (
    <div className="examinerStore">
      <div className="topTabs">
        <Tabs onChange={activeChange}>
          <Tabs.Tab title="考官列表" key="1" />
          <Tabs.Tab title="培训统计" key="2" />
        </Tabs>
      </div>

      <div className="examinerList">
        <NoData list={activeList} />
        {activeList.map((v) => examineList(v))}
      </div>
    </div>
  );
};

export default ExaminerStore;
