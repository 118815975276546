import { Dropdown, Radio, Space, Popover, Tag } from "antd-mobile";
import { DownOutline } from "antd-mobile-icons";
import { Fragment, useState } from "react";
import getApplyDetail from "../util/getApplyDetail";
import { useEffect } from "react";
import examPopAction from "../util/applyPopAction";
import Request from "../../../unit/request";
import NoData from "../../../components/noData/noData";
const ApplyExam = (props) => {
  const [isPassedScore, setIsPassedScore] = useState(0);
  const [applyStudentList, setApplyStudentList] = useState([]);
  const getData = () => {
    const params = {
      type: "student",
      exam_id: props.exam_id,
      filter: (v) => {
        return v.verify === 1;
      },
    };
    getApplyDetail(params).then((res) => {
      setApplyStudentList(res);
    });
  };
  const examScoreExamine = (v, verify) => {
    Request({
      url: "/admin/audit/exam_pass",
      method: "post",
      data: {
        exam_id: props.exam_id,
        stu_id: v.student_id,
        passed: verify,
        verify: 1,
      },
    }).then((res) => {
      getData();
    });
  };
  const toDetailPage = (item) => {
    sessionStorage.studentEdit = JSON.stringify(item);
    navigator(`/report?type=admin`);
  };
  useEffect(() => {
    getData();
    let _class = document.body.className;
    document.body.setAttribute("class", `${_class} examDetailPopBody`);
    return () => {
      document.body.removeAttribute(
        "class",
        _class.replace("examDetailPopBody", "")
      );
    };
  }, []);
  return (
    <Fragment>
      <div className="examiner">
        <div className="examinerBlockTitle">
          评分审核
          <Dropdown arrow={<DownOutline />} onChange={(v) => !v && getData()}>
            <Dropdown.Item
              key="sorter1"
              title={isPassedScore === 0 ? "待审核" : "已审核"}
            >
              <div className="radioBox">
                <Radio.Group defaultValue={0} onChange={setIsPassedScore}>
                  <Space direction="vertical">
                    <Radio value={0}>待审核</Radio>
                    <Radio value={1}>已审核</Radio>
                  </Space>
                </Radio.Group>
              </div>
            </Dropdown.Item>
          </Dropdown>
        </div>
        <div className="applyList">
          <NoData list={applyStudentList} />
          {applyStudentList.map((v, i) => (
            <div className="applyItemBox">
              <div className="applyItem">
                <div className="applyLeft" onClick={() => toDetailPage(v)}>
                  <img src={v.img} className="applyImg" alt="" />
                </div>
                <div className="applyRight">
                  <div className="applyInfo" onClick={() => toDetailPage(v)}>
                    <div className="applyInfoItem applyInfoName">{v.name}</div>
                    <div className="applyInfoItem">
                      性别：
                      {v.gender === 1 ? "男" : v.gender === 2 ? "女" : "未知"}
                    </div>
                    <div className="applyInfoItem">
                      手机号：
                      {v.phoneNumber}
                    </div>
                    <div className="applyInfoItem">
                      <svg className="icon" aria-hidden="true">
                        <use xlinkHref="#icon-weizhi1"></use>
                      </svg>
                      {v.address && v.address.split("|@|")}
                    </div>
                  </div>
                  {v.passed === 0 ? (
                    <Popover.Menu
                      actions={examPopAction}
                      placement="bottom-start"
                      onAction={(node) => {
                        examScoreExamine(v, node.key);
                      }}
                      stopPropagation={["click"]}
                      trigger="click"
                    >
                      <div className="applyBtn">审核</div>
                    </Popover.Menu>
                  ) : v.passed === 1 ? (
                    <Tag color="#87d068" fill="outline">
                      审核通过
                    </Tag>
                  ) : (
                    <Tag color="#ff6430" fill="outline">
                      审核未通过
                    </Tag>
                  )}
                </div>
              </div>
              <div className="scoreItemBox">
                {v.scoreList.length === 0 && (
                  <div className="scoreItem">
                    <p>考试成绩</p>
                    待评分
                  </div>
                )}
                {v.scoreList.map((s) => (
                  <div className="scoreItem">
                    <p>{s.name}</p>
                    {s.score}
                  </div>
                ))}
                {(props.exam_category === "足球" ||
                  props.exam_category === "篮球") && (
                  <div className="scoreItem">
                    <p>赛事评分：</p>
                    <div className="scoreNum">
                      {v.match_score ? `${v.match_score}分` : "未评分"}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default ApplyExam;
