import "./publishExam.scss";
import { Tabs, Tag, Popover, Toast } from "antd-mobile";
import { useLocation, useNavigate } from "react-router-dom";
import { CloseShieldOutline, CheckShieldOutline } from "antd-mobile-icons";
import { useEffect, useState } from "react";
import getCloudImg from "../../unit/getCloudImg";
import Request from "../../unit/request";
import { useLoginStore } from "../../store";
import qs from "qs";
const tabsList = [
  {
    label: "_考试",
    key: "1",
  },
  {
    label: "_培训",
    key: "2",
  },
  {
    label: "_新闻",
    key: "3",
  },
];
const PublishExam = () => {
  sessionStorage.removeItem("newsEdit");
  const navigate = useNavigate();
  const location = useLocation();
  const [isExamine, setIsExamine] = useState(false);
  useEffect(() => {
    const _isExamine = location.pathname === "/adminExam";
    _isExamine && setIsExamine(true);
  }, [location]);
  const toAddExam = () => {
    switch (tabsActive) {
      case "1":
        navigate("/addExam");
        break;
      case "2":
        navigate("/addCultivate");
        break;
      case "3":
        navigate("/addNews");
        break;
      default:
        break;
    }
  };
  const [activeList, setActiveList] = useState([]);
  const { GetUserInfo, loginInfo } = useLoginStore((state) => state);
  const getExamList = (user) => {
    Request({
      url: "/exam/list",
      method: "post",
      data:
        location.pathname === "/adminExam"
          ? null
          : {
              ins_id: loginInfo.userInfo.institution_id,
            },
    }).then(
      (res) => {
        getCloudImg({
          key: "img",
          list: res.data,
        }).then((list) => {
          setActiveList(list);
        });
      },
      (err) => {
        setActiveList([]);
      }
    );
  };
  const getCultivateList = (user) => {
    setActiveList([]);
    //Request({
    //  url: "/admin/train/list",
    //  method: "post",
    //  data: {
    //    ins_id: user.userInfo.institution_id,
    //  },
    //}).then((res) => {
    //  getCloudImg({
    //    key: "img",
    //    list: res.data,
    //  }).then(
    //    (list) => {
    //      setActiveList(list);
    //    },
    //    (err) => {
    //      setActiveList([]);
    //    }
    //  );
    //});
  };
  const getNewsList = (user) => {
    Request({
      url: "/admin/news/myAddList",
      method: "post",
      data: {
        ins_id: user.userInfo.institution_id,
      },
    }).then(
      (res) => {
        getCloudImg({
          key: "img",
          list: res.data.myAddList,
        }).then(
          (list) => {
            console.log(list);
            setActiveList(list);
          },
          (err) => {
            setActiveList([]);
          }
        );
      },
      (err) => {
        setActiveList([]);
      }
    );
  };
  const tabsActiveInterFace = {
    exam: "1",
    train: "2",
    news: "3",
  };
  let _tabsActive;
  const { type } = qs.parse(location.search, { ignoreQueryPrefix: true });
  if (type) {
    _tabsActive = tabsActiveInterFace[type];
  }
  const [tabsActive, setTabsAvtive] = useState(_tabsActive || "1");
  const toDetail = (e) => {
    navigate(`/examDetail?exam_id=${e.exam_id}&type=admin`);
  };
  const examine = (type, id, verify) => {
    const url = `/admin/audit/${type}`;
    let data = {
      verify,
    };
    data[`${type}_id`] = id;
    Request({
      url,
      method: "post",
      data,
    }).then((res) => {
      getList(loginInfo);
    });
  };
  const examPopAction = [
    { key: "1", icon: <CheckShieldOutline />, text: "通过" },
    { key: "2", icon: <CloseShieldOutline />, text: "不通过" },
  ];
  const rootBtn = (v, id, type) => {
    const { verify } = v;
    let el = null;
    switch (verify) {
      case 0:
        if (isExamine) {
          el = (
            <Popover.Menu
              actions={examPopAction}
              placement="bottom-start"
              onAction={(node) => {
                examine(type, id, node.key);
              }}
              stopPropagation={["click"]}
              trigger="click"
            >
              <div className="examineBtn">审核</div>
            </Popover.Menu>
          );
        } else {
          el = (
            <Tag color="primary" fill="outline">
              审核中
            </Tag>
          );
        }
        break;
      case 1:
        el = (
          <Tag color="#87d068" fill="outline">
            审核通过
          </Tag>
        );
        break;
      case 2:
        el = (
          <Tag color="#ff6430" fill="outline">
            审核未通过
          </Tag>
        );
        break;
      default:
        break;
    }
    return el;
  };
  const getList = (user) => {
    switch (tabsActive) {
      case "1":
        getExamList(user);
        break;
      case "2":
        getCultivateList(user);
        break;
      case "3":
        getNewsList(user);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    GetUserInfo().then((user) => {
      getList(user);
    });
  }, [tabsActive]);
  const toNewsDetail = (e) => {
    navigate(`/addNews?news_id=${e.id}&type=${isExamine ? "admin" : "edit"}`);
  };
  const examList = (v) => {
    return (
      <div className="examItem">
        <img src={v.img} alt="" onClick={() => toDetail(v)} />
        <div className="examInner">
          <div className="examInfo" onClick={() => toDetail(v)}>
            <p className="examTitle">{v.exam_name}</p>
            <Tag color="primary" fill="outline">
              {v.exam_category}
              {v.exam_rank && `${v.exam_rank}级`}
            </Tag>
            <p className="examAddress">
              <svg className="icon" aria-hidden="true">
                <use xlinkHref="#icon-weizhi1"></use>
              </svg>
              {v.exam_address && v.exam_address.split("|@|").join("")}
            </p>
            <p className="examDate">
              <svg className="icon" aria-hidden="true">
                <use xlinkHref="#icon-shijian"></use>
              </svg>
              {v.exam_date && v.exam_date.split(" ")[0]}
            </p>
          </div>
          {rootBtn(v, v.exam_id, "exam")}
        </div>
      </div>
    );
  };
  const cultivateList = (v) => {};
  const newsList = (v) => {
    return (
      <div className="newsItem">
        <div className="newsTitle" onClick={() => toNewsDetail(v)}>
          <p>{v.title}</p>
        </div>
        {v.img && (
          <img
            className="newsItemImg"
            src={v.img}
            onClick={() => toNewsDetail(v)}
            alt=""
          />
        )}
        <div className="adminNewsData">
          <div onClick={() => toNewsDetail(v)} className="adminNewsInfo">
            <div className="newsInfoItem">
              新闻日期：{v.date && v.date.split(" ")[0]}
            </div>
            <div className="newsInfoItem">新闻来源：{v.href}</div>
            <div className="newsInfoItem">
              {v.type === "news" ? (
                <Tag color="primary">新闻</Tag>
              ) : (
                <Tag color="success">公告</Tag>
              )}
            </div>
          </div>
          {rootBtn(v, v.id, "news")}
        </div>
      </div>
    );
  };
  const createActiveList = (v) => {
    switch (tabsActive) {
      case "1":
        return examList(v);
      case "2":
        return cultivateList(v);
      case "3":
        return newsList(v);
      default:
        break;
    }
  };
  const reText = (v) => {
    const base = isExamine ? "审核" : "发布";
    return v.replace(/_/g, base);
  };
  return (
    <div
      className={`publishExamList publishExam ${isExamine ? "isExamine" : ""} `}
    >
      {!isExamine && (
        <div className="topTabs">
          <Tabs
            onChange={(e) => {
              setTabsAvtive(e);
            }}
          >
            {tabsList.map((v) => (
              <Tabs.Tab title={reText(v.label)} key={v.key} />
            ))}
          </Tabs>
        </div>
      )}
      <div>
        <div className="examBox">
          {activeList.length <= 0 && (
            <div className="examBoxNoThing">
              {tabsActive === "1"
                ? reText("暂无_考试")
                : tabsActive === "2"
                ? reText("暂无_培训")
                : reText("暂无_新闻")}
            </div>
          )}
          {activeList.map((v) => createActiveList(v))}
        </div>
      </div>

      {!isExamine && (
        <div className="addExam" onClick={toAddExam}>
          {tabsActive === "1"
            ? "发布考试"
            : tabsActive === "2"
            ? "发布培训"
            : "发布新闻"}
        </div>
      )}
    </div>
  );
};

export default PublishExam;
