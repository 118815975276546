import { Dropdown, Radio, Space, Tag, Toast } from "antd-mobile";
import { DownOutline } from "antd-mobile-icons";
import { useRef, useState } from "react";
import getApplyDetail from "../util/getApplyDetail";
import examPopAction from "../util/applyPopAction";
import { useEffect } from "react";
import Request from "../../../unit/request";
import { useNavigate } from "react-router-dom";
import NoData from "../../../components/noData/noData";
const ApplyExam = (props) => {
  const navigate = useNavigate();
  const [type, setType] = useState("student");
  const [apply, setApply] = useState(0);
  const [applyStudentList, setApplyStudentList] = useState([]);
  const getData = () => {
    const params = {
      type,
      exam_id: props.exam_id,
      filter: (v) => {
        return true;
      },
    };
    getApplyDetail(params).then((res) => {
      setApplyStudentList(res);
    });
  };
  const toDetailPage = (item) => {
    if (type === "student") {
      sessionStorage.studentEdit = JSON.stringify(item);
      navigate(`/report?type=admin`);
    } else {
      navigate(`/examinerReport?examine_id=${item.detail_id}&type=apply`);
    }
  };
  useEffect(() => {
    getData();
    let _class = document.body.className;
    document.body.setAttribute("class", `${_class} examDetailPopBody`);
    return () => {
      document.body.removeAttribute(
        "class",
        _class.replace("examDetailPopBody", "")
      );
    };
  }, []);
  return (
    <div className="examiner">
      <div className="examinerBlockTitle">
        报名信息
        {
          <Dropdown
            className="dropdownBox"
            arrow={<DownOutline />}
            onChange={(v) => !v && getData()}
          >
            <Dropdown.Item
              key="sorter"
              title={type === "student" ? "学生" : "考官"}
            >
              <div className="radioBox">
                <Radio.Group defaultValue="student" onChange={setType}>
                  <Space direction="vertical">
                    <Radio value="student">学生</Radio>
                    <Radio value="examine">考官</Radio>
                  </Space>
                </Radio.Group>
              </div>
            </Dropdown.Item>
          </Dropdown>
        }
      </div>
      <div className="applyList">
        <NoData list={applyStudentList} />
        {applyStudentList.map((v, i) => (
          <div className="applyItemBox">
            <div className="applyItem">
              <div className="applyLeft" onClick={() => toDetailPage(v)}>
                <img src={v.img} className="applyImg" alt="" />
              </div>
              <div className="applyRight">
                <div className="applyInfo" onClick={() => toDetailPage(v)}>
                  <div className="applyInfoItem applyInfoName">{v.name}</div>
                  <div className="applyInfoItem">
                    性别：
                    {v.gender === 1 ? "男" : v.gender === 2 ? "女" : "未知"}
                  </div>
                  <div className="applyInfoItem">
                    手机号：
                    {v.phoneNumber}
                  </div>
                  <div className="applyInfoItem">
                    <svg className="icon" aria-hidden="true">
                      <use xlinkHref="#icon-weizhi1"></use>
                    </svg>
                    {v.address && v.address.split("|@|")}
                  </div>
                </div>
                {apply === 0 ? (
                  <Tag color="#87d068" fill="outline">
                    审核中
                  </Tag>
                ) : v.verify === 1 ? (
                  <Tag color="#87d068" fill="outline">
                    审核通过
                  </Tag>
                ) : (
                  <Tag color="#ff6430" fill="outline">
                    审核未通过
                  </Tag>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApplyExam;
