import { Popover, Tag } from "antd-mobile";
import { DownOutline } from "antd-mobile-icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NoData from "../../components/noData/noData";
import { useLoginStore } from "../../store";
import getCloudImg from "../../unit/getCloudImg";
import Request from "../../unit/request";
import "./qualityControl.scss";
const QualityControl = () => {
  const [examList, setExamList] = useState([]);
  const getData = (ins_id) => {
    Request({
      url: "/admin/exam/survey",
      method: "post",
      data: {
        ins_id,
      },
    }).then((res) => {
      getCloudImg({
        list: res.data.examSurvey,
        key: "exam_field",
      }).then((list) => {
        const arr = list || [];
        arr.forEach((v) => {
          if (v.stu_Num === 0) {
            v.stu_Num = `暂无`;
            v.stu_pass_num = `暂无`;
            v.stu_pass_rate = `暂无`;
            v.stu_eval_avg = `暂无`;
          } else {
            v.stu_Num = `${v.stu_Num}人`;
            v.stu_pass_num = `${v.stu_pass_num}人`;
            v.stu_pass_rate = `${v.stu_pass_rate * 100}%`;
            v.stu_eval_avg = v.stu_eval_avg ? `${v.stu_eval_avg}分` : "暂无";
          }
        });
        setExamList(list);
      });
    });
  };
  const navigate = useNavigate();
  const toDetail = (v) => {
    navigate(`/examDetail?exam_id=${v.exam_id}&type=survey`);
  };
  const { GetUserInfo } = useLoginStore((state) => state);
  const [actions, setActions] = useState([]);
  const [indsName, setIndsName] = useState("");
  const checkedIns = (node) => {
    setIndsName(node.text);
    getData(node.key);
  };
  const getInsList = (ins_id) => {
    Request({
      url: "/institution/list",
      method: "post",
      data: {
        ins_id: isAdmin ? "" : ins_id,
      },
    }).then((res) => {
      const arr = res.data.map((v) => {
        return {
          key: v.institution_id,
          text: v.name,
        };
      });
      if (!isAdmin && arr.length > 0) {
        arr.splice(0, 0, {
          key: ins_id,
          text: "全部",
        });
      }
      setIndsName(arr[0].text);
      setActions([...arr]);
      getData(arr[0].key || "");
    });
  };
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    GetUserInfo().then(({ userInfo }) => {
      if (userInfo.user_level_id === 5) {
        setIsAdmin(true);
      }
      if (userInfo.user_level_id === 3) {
        getData(userInfo.institution_id);
      }
      if (userInfo.user_level_id === 4) {
        getInsList(userInfo.institution_id);
      }
    });
  }, []);
  return (
    <div>
      {actions.length > 0 && (
        <Popover.Menu
          actions={actions}
          placement="bottom-start"
          trigger="click"
          onAction={(node) => checkedIns(node)}
        >
          <div className="popInsdList">
            {indsName} <DownOutline />
          </div>
        </Popover.Menu>
      )}

      <div className="examList">
        <NoData list={examList} />
        {examList.map((v) => (
          <div className="examItem" onClick={() => toDetail(v)}>
            <img src={v.exam_field} alt="" />
            <div className="examInner">
              <div className="examInfo">
                <p className="examTitle">{v.exam_name}</p>
                <Tag color="primary" fill="outline">
                  {v.exam_category}
                  {v.exam_rank && `${v.exam_rank}级`}
                </Tag>
                <p className="examAddress">
                  <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#icon-weizhi1"></use>
                  </svg>
                  {v.exam_address && v.exam_address.split("|@|").join("-")}
                </p>
                <p className="examDate">
                  <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#icon-shijian"></use>
                  </svg>
                  {v.exam_date.split(" ")[0]}
                </p>
              </div>
              <div className="examSurvey">
                <div className="examSurveyItem">
                  考试人数：<span>{v.stu_Num}</span>
                </div>
                <div className="examSurveyItem">
                  考试通过：<span>{v.stu_pass_num}</span>
                </div>
                <div className="examSurveyItem">
                  考试通过率：<span>{v.stu_pass_rate}</span>
                </div>
                <div className="examSurveyItem">
                  平均评分：<span>{v.stu_eval_avg}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default QualityControl;
