import { appid, secret, baseHost } from "./config";
import Cookies from "js-cookie";
import Request from "./request";
import qs from "qs";
const setToken = (res) => {
	Cookies.set('token', res.access_token, {
		expires: new Date(new Date().getTime() + (res.expires_in * 1000))
	})
	Cookies.set('openid', res.openid, {
		expires: new Date(new Date().getTime() + (res.expires_in * 1000))
	})
	Cookies.set('refreshToken', res.refresh_token, {
		expires: 30
	})
}
export const getToken = (code) => { // 获取token
	return new Promise((r, j) => {
		const tokenParams = {
			appid,
			secret,
			code,
			grant_type: 'authorization_code'
		}
		Request({
			url: `/sns/oauth2/access_token?${qs.stringify(tokenParams)}`,
			method: 'get',
			urlType: 'weChat'
		}).then(res => {
			if (!res.errcode) {
				setToken(res)
				r([res.access_token, res.openid])
			} else {
				j(res)
			}
		}, err => {
			j(err)
		})
	})
};
export const refreshToken = (refresh_token) => { // 刷新token
	return new Promise((r, j) => {
		const tokenParams = {
			appid,
			grant_type: 'refresh_token',
			refresh_token
		}
		Request({
			url: `/sns/oauth2/refresh_token?${qs.stringify(tokenParams)}`,
			method: 'get',
			urlType: 'weChat'
		}).then(res => {
			if (!res.errcode) {
				setToken(res)
				r([res.access_token, res.openid])
			} else {
				j(res)
			}
		}, err => {
			j(err)
		})
	})
}
export const getLoginInfo = (token, openid) => { // 通过token与openid获取用户信息
	return new Promise((r, j) => {
		const query = `access_token=${token}&openid=${openid}&lang=zh_CN`
		Request({
			url: `/sns/userinfo?${query}`,
			method: 'get',
			urlType: 'weChat'
		}).then(res => {
			if (!res.errcode) {
				r(res)
			} else {
				j(res)
			}
		}, err => {
			j(err)
		})
	})
};
export const getUserInfo = () => {
	return new Promise((r, j) => {
		Request({
			url: '/user/info',
			method: 'post'
		}).then(res => {
			r(res)
		}, err => {
			j(err)
		})
	})
}
export const ToLogin = (redirect_uri) => { // 跳转到微信授权页面
	const host = "https://open.weixin.qq.com/connect/oauth2/authorize";
	const redirect_base = `${baseHost}${redirect_uri}`;
	const params = {
		appid,
		redirect_uri: redirect_base,
		response_type: "code",
		scope: "snsapi_userinfo",
		state: "0",
	};
	const loginHref = `${host}?${qs.stringify(params)}#wechat_redirect`;
	sessionStorage.loginHref = loginHref
	window.location.href = loginHref;
};
export const login = (loginParams) => {
	return new Promise((r, j) => {
		Request({
			url: '/user/loginByWeixin',
			method: 'post',
			data: {
				code: loginParams.code,
				userInfo: loginParams.userInfo
			}
		}).then(res => {
			Cookies.set('requestToken', res.data.token, {
				expires: 1
			})
			r(res.data.userInfo)
		}, err => {
			j(err)
		})
	})
}