import {
  Form,
  Input,
  Button,
  Picker,
  DatePicker,
  Toast,
  Dialog,
} from "antd-mobile";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import verify from "../../../unit/verify.js";
import qs from "qs";
import "./addNews.css";
import Request from "../../../unit/request";
import UploaderImg from "../../../components/uploadImg/uploadImg";
import getCloudImg from "../../../unit/getCloudImg.js";
import dayjs from "dayjs";
const newsTypeList = [
  [
    {
      label: "公告（banner区）",
      value: "banner",
    },
    {
      label: "新闻（新闻列表区）",
      value: "news",
    },
  ],
];
const Report = () => {
  const { search } = useLocation();
  const [formData, setFormData] = useState({});
  const deleteNews = () => {
    Dialog.confirm({
      content: "确认删除此条新闻？",
      onConfirm: async () => {
        await Request({
          url: "/admin/news/delete",
          method: "POST",
          data: {
            news_id: newsid.current,
          },
        });
        Toast.show({
          icon: "success",
          content: "删除成功",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1500);
      },
    });
  };
  const navigate = useNavigate();
  const submit = (e) => {
    const params = e;
    if (params.img) {
      params.img = params.img[0]?.serverId;
    }
    if (params.date) {
      params.date = dayjs(params.date).format("YYYY-MM-DD");
    }
    if (params.type) {
      params.type = params.type[0];
    }
    if (richText.current) {
      params.richText = richText.current;
    }
    let rule = [
      { key: "title", msg: "请输新闻标题" },
      { key: "href", msg: "请输新闻来源" },
      { key: "date", msg: "请选择新闻日期" },
      { key: "type", msg: "请选择新闻类型" },
      { key: "richText", msg: "请编辑新闻内容" },
    ];
    if (params.type === "banner") {
      rule.push({ key: "img", msg: "请上传新闻封面图" });
    }
    const _verify = verify(params, rule);
    if (_verify) {
      Toast.show({
        content: _verify.msg,
      });
      return;
    }
    if (params.richText) {
      params.richText = params.richText.replace(/\\/g, "[|");
      params.richText = params.richText.replace(/\//g, "|]");
    }
    const data = {
      form: params,
    };
    if (isEdit) {
      data.news_id = newsid.current;
    }

    const url = isEdit ? "/admin/news/modify" : "/admin/news/add";
    Request({
      url,
      method: "post",
      data,
    }).then(
      (res) => {
        Toast.show({
          icon: "success",
          content: "提交成功",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1500);
      },
      (err) => {
        Toast.show({
          icon: "fail",
          content: err.msg,
        });
      }
    );
  };
  const form = useRef(null);
  const richText = useRef("");
  const _setInit = () => {
    try {
      let session = JSON.parse(sessionStorage.newsEdit || "{}") || {};
      let _init = {
        ...session,
      };
      richText.current = _init.richText;
      _init.date = new Date(_init.date);
      getCloudImg({
        key: "img",
        alias: "viewImg",
        list: [_init],
      }).then((list) => {
        setFormData(list[0]);
        if (form.current) {
          form.current.setFieldsValue(_init);
        }
      });
    } catch (error) {}
  };
  const toEditor = () => {
    const saveParams = {
      ...form.current.getFieldsValue(),
      ...formData,
    };
    let newsEdit;
    try {
      newsEdit = JSON.parse(sessionStorage.newsEdit);
    } catch (error) {
      newsEdit = {};
    }
    newsEdit = {
      ...newsEdit,
      ...saveParams,
    };
    sessionStorage.newsEdit = JSON.stringify(newsEdit);
    navigate(`/addNewsInner${isAdmin ? "?type=edit" : ""}`);
  };
  const newsid = useRef("");
  const getNewsDetail = () => {
    Request({
      url: "/news/detail",
      method: "post",
      data: {
        news_id: newsid.current,
      },
    }).then((res) => {
      const detail = res.news_detail[0];
      detail.date = new Date(detail.date);
      detail.type = [detail.type];
      detail.richText = detail.richText.replace(/\[\|/g, "\\");
      detail.richText = detail.richText.replace(/\|\]/g, "/");
      richText.current = detail.richText;
      getCloudImg({
        key: "img",
        list: [{ img: detail.img }],
      }).then((list) => {
        detail.img = [list[0]];
        setFormData(detail);
        form.current.setFieldsValue(detail);
      });
    });
  };
  const examine = (verify) => {
    let data = {
      verify,
      news_id: formData.id,
    };
    Request({
      url: "/admin/audit/news",
      method: "post",
      data,
    }).then((res) => {
      Toast.show({
        icon: "success",
        content: "审核已完成",
      });
      setTimeout(() => {
        navigator(-1);
      }, 1500);
    });
  };
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const { news_id, type } = qs.parse(search, { ignoreQueryPrefix: true });
    if (type === "admin") {
      setIsAdmin(true);
    }
    if (type === "edit") {
      setIsEdit(true);
    }
    if (news_id) {
      newsid.current = news_id;
      getNewsDetail();
      return;
    }
    _setInit();
  }, []);
  return (
    <div className="report">
      <Form
        //disabled={params.type === "admin"}
        ref={form}
        layout="horizontal"
        mode="card"
        onFinish={submit}
        footer={
          !isAdmin ? (
            <div>
              <Button block type="submit" color="primary" size="large">
                提交审核
              </Button>
              {isEdit && (
                <Button
                  onClick={deleteNews}
                  block
                  className="deleteNews"
                  color="danger"
                  size="large"
                >
                  删除新闻
                </Button>
              )}
            </div>
          ) : formData.verify === 0 ? (
            <div className="adminBtn">
              <Button
                block
                shape="rounded"
                color="primary"
                onClick={() => examine(1)}
              >
                通过
              </Button>
              <Button
                block
                shape="rounded"
                color="danger"
                onClick={() => examine(2)}
              >
                不通过
              </Button>
            </div>
          ) : formData.verify === 1 ? (
            <div className="adminBtnOther">
              <Button block shape="rounded" color="primary">
                审核已通过
              </Button>
            </div>
          ) : (
            <div className="adminBtnOther">
              <Button block shape="rounded" color="danger">
                审核未通过
              </Button>
            </div>
          )
        }
      >
        <Form.Header>新闻信息</Form.Header>
        <Form.Item label="新闻标题" name="title" required={false}>
          <Input readOnly={isAdmin} placeholder="请输入标题" />
        </Form.Item>
        <Form.Item label="新闻来源" name="href" required={false}>
          <Input readOnly={isAdmin} placeholder="请输入新闻来源" />
        </Form.Item>
        <Form.Item
          label="新闻日期"
          onClick={(e, datePickerRef) => {
            if (isAdmin) {
              return;
            }
            datePickerRef.current?.open();
          }}
          trigger="onConfirm"
          name="date"
          required={false}
          initialValue={new Date()}
        >
          <DatePicker
            title="选择新闻日期"
            //min={new Date(new Date().getDate() - 7)}
            max={new Date()}
          >
            {(value) => {
              console.log(value, value === "Invalid Date");
              if (!value || value === "Invalid Date") {
                console.log(1);
                return "请选择新闻日期";
              } else {
                console.log(value);
                return dayjs(value).format("YYYY-MM-DD");
              }
            }}
          </DatePicker>
        </Form.Item>
        <Form.Item
          label="新闻类型"
          required={false}
          name="type"
          trigger="onConfirm"
          onClick={(e, pickerRef) => {
            if (isAdmin) {
              return;
            }
            pickerRef.current?.open();
          }}
        >
          <Picker columns={newsTypeList}>
            {(e) => {
              return <div>{e[0]?.label || "请选择新闻类型"}</div>;
            }}
          </Picker>
        </Form.Item>
        <Form.Subscribe to={["type"]}>
          {(form) =>
            form.type &&
            form.type[0] === "banner" && (
              <Form.Item label="封面图片" name="img" required={false}>
                <UploaderImg path="addNews" isView={isAdmin} />
              </Form.Item>
            )
          }
        </Form.Subscribe>
        <Form.Item label="新闻内容" name="href" required={false}>
          <div className="editLink" onClick={toEditor}>
            {isAdmin ? "点击查看" : "点击前往编辑"}
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
export default Report;
