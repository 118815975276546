import "./addNews.css";
import { useQuill } from "react-quilljs";
//import ImageResize from "quill-image-resize-module-react";
import "quill/dist/quill.snow.css";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import upload from "../../../unit/uploadImg";
import qs from "qs";
//Quill.register("modules/ImageResize", ImageResize);
const AddNews = () => {
  const toolbarOptions = [
    ["bold", "italic", "underline", "link", "image"], // toggled buttons
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],
  ];
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: toolbarOptions,
    },
  });
  const navigate = useNavigate();
  const saveEdit = () => {
    let params = {};
    try {
      params = JSON.parse(sessionStorage.newsEdit);
    } catch (error) {
      params = {};
    }
    params.richText = richText.current;
    sessionStorage.newsEdit = JSON.stringify(params);
    navigate(-1);
  };
  const insertToEditor = (url, cloud) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, "image", url);
  };
  const resolveImg = (file) => {
    let files = file.target.files;
    upload({
      files,
      path: "addNewsInner",
    }).then(
      (res) => {
        insertToEditor(res[0].download_url);
        file.target.value = null;
      },
      (err) => {
        file.target.value = null;
      }
    );
  };
  const richText = useRef("");
  const { search } = useLocation();
  const { type } = qs.parse(search, { ignoreQueryPrefix: true });
  useEffect(() => {
    if (quill) {
      let _richText = "";
      try {
        _richText = JSON.parse(sessionStorage.newsEdit).richText || "";
      } catch (error) {
        _richText = "";
      }
      quill.root.innerHTML = _richText;
      quill.getModule("toolbar").addHandler("image", () => {
        document.querySelector("#addNewsImg").click();
      });
      quill.on("text-change", () => {
        richText.current = quill.root.innerHTML;
      });
      if (type === "edit") {
        quill.enable(false);
      }
    }
  }, [quill]);
  const toolbar = document.querySelector(".ql-toolbar");
  const [toolbarHeight, setToolbarHeight] = useState(0);
  useLayoutEffect(() => {
    if (toolbar) {
      console.log(toolbar.offsetHeight, "toolbar.offsetHeight");
      toolbar.style.marginTop = `-${toolbar.offsetHeight}px`;
      setToolbarHeight(toolbar.offsetHeight);
    }
  }, [toolbar]);
  return (
    <div className="quill" style={{ paddingTop: `${toolbarHeight}px` }}>
      <input type="file" id="addNewsImg" onChange={resolveImg} />
      <div ref={quillRef} />
      {type !== "edit" && (
        <div className="saveBtn" onClick={saveEdit}>
          保存
        </div>
      )}
    </div>
  );
};

export default AddNews;
