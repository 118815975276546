import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import getCloudImg from "../../unit/getCloudImg";
import qs from 'qs'
import Request from "../../unit/request";
import "./newsDetail.css";

const NewsDetail = () => {
	const navigate = useNavigate()
	const location = useLocation();
	const [news, setNews] = useState({
		richText: "<div></div>"
	});
	const news_id = useRef("");
	const getNewsDetail = () => {
		Request({
			url: "/news/detail",
			method: "post",
			data: {
				news_id: news_id.current,
			},
		}).then((res) => {
			getCloudImg({
				key: "img",
				alias: "viewImg",
				list: res.news_detail,
			}).then((list) => {
				list[0].richText = list[0].richText.replace(/\[\|/g, "\\");
				list[0].richText = list[0].richText.replace(/\|\]/g, "/");
				console.log(list[0].richText)
				setNews(list[0]);
			});
		});
	}
	const toBack = () => {
		navigate(-1);
	};
	useEffect(() => {
		const editID = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};
		if (editID.news_id) {
			news_id.current = editID.news_id;
			getNewsDetail();
		}
	}, []);
	return (
		<div className="newsDetail">
			<p className="backBtn" onClick={toBack}>
				&lt; 返回
			</p>
			<div className="newsDetailContent">
				<div className="newsDetailTitle">
					{news.title}
				</div>
				<div className="newsDetailAddress">{news.href}</div>
				<div className="newsDetailDate">{news.date && news.date.split(" ")[0]}</div>

				<div className="newsDetailInner" dangerouslySetInnerHTML={{ __html: news.richText }}></div>
			</div>
		</div>
	);
};

export default NewsDetail;
